import { ArialMT } from '@/assets/fonts/ArialMT'
import {
  MuktaLight,
  MuktaLightExt,
  MuktaLightDevanagari,
  MuktaMedium,
  MuktaMediumExt,
  MuktaMediumDevanagari,
} from '@/assets/fonts/Mukta'

export const createStyle = () => `
  /* devanagari */
  @font-face {
    font-family: "Mukta-Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("${MuktaLightDevanagari.uri}") format("${MuktaLightDevanagari.format}");
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  
  /* latin-ext */
  @font-face {
    font-family: "Mukta-Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("${MuktaLightExt.uri}") format("${MuktaLightExt.format}");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  /* latin */
  @font-face {
    font-family: "Mukta-Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("${MuktaLight.uri}") format("${MuktaLight.format}");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  /* devanagari */
  @font-face {
    font-family: "Mukta-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("${MuktaMediumDevanagari.uri}") format("${MuktaMediumDevanagari.format}");
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  
  /* latin-ext */
  @font-face {
    font-family: "Mukta-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("${MuktaMediumExt.uri}") format("${MuktaMediumExt.format}");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  /* latin */
  @font-face {
    font-family: "Mukta-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("${MuktaMedium.uri}") format("${MuktaMedium.format}");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  @font-face {
    font-family: "ArialMT";
    src: url("${ArialMT.uri}") format("${ArialMT.format}");
  }
`
